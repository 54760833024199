.checkbox-container {
  .form-control-label {
    align-items: start;
  }
  .checkbox {
    color: $primary !important;
    padding: 0;
    & > input {
      color: $primary !important;
    }
    &.Mui-checked > input {
      color: $primary !important;
    }
  }
}
