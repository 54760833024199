/* style-lint-disable */

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$xs-breakpoint: 300px;
$sx-breakpoint: 372px;
$xsm-breakpoint: 490px;
$sm-breakpoint: 600px;
$md-breakpoint: 900px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1600px;
$xxl-breakpoint: 2180px;

$grid-breakpoints: (
  xsBreakpoint: $xs-breakpoint,
  smBreakpoint: $sm-breakpoint,
  mdBreakpoint: $md-breakpoint,
  lgBreakpoint: $lg-breakpoint,
  xlBreakpoint: $xl-breakpoint,
);

$purple: #391474;
$purple-v2: #7236ae;
$light-purple: #F8D5FD;
$dark-purple: #18112f;
$less-dark-purple: #211c33;
$lighter-purple-v2: #C06DF3;
$lighter-border: #FA7DE0;
$lighter-purple: #fae2fe;
$primary: #7236ae;
$lightest-purple-v2: #efe5ef;
$lighter-primary: #025496;
$dark-primary: #002A4C;
$moderate-primary: #236AA3;
$light-primary: #005395;
$lightest-primary: #236AA31A;
$backdrop-primary: rgba(48, 37, 87, 0.4);
$secondary: #2792bf;
$light-secondary: #DBEDFB;
$lighter-secondary: #DBEEFA;
$lightest-secondary: #E9F4FC;
$error: #c5291c;
$moderate-red: #9D261A;
$gray: #c3c0ce;
$shed-grey: #8DA1AE;
$light-gray: #8b8b8b;
$lightest-gray: #F6F6F6;
$medium-gray: #E3E3E3;
$moderate-gray: #f9f9f9;
$very-light-gray: #e0e0e0;
$dark-gray: #656565;
$dark-gray: #3a3a3a;
$border-gray: #E5E5E5;
$white: #ffffff;
$black: #000000;
$text-black: #3a3a3a;
$text-dark-black: #313131;
$field-icon-black: #211e1d;
$success: #46962b;
$warning: #ff0000;
$warn-red: #BE1212;
$warn-border: #B71111;
$field-background: #f9f9f9;
$linear-background: #391474;
$linear-green-background-light: transparent linear-gradient(90deg, #95C11F 15%, #199500 85%)
  0% 0% no-repeat padding-box;
$linear-green-background: transparent linear-gradient(90deg, #199500 0%, #004b0f 100%)
  0% 0% no-repeat padding-box;
$event-header-background: transparent linear-gradient(90deg, #236AA2 55%, #1236538F 0%) 0% 0% no-repeat padding-box;
$yellow: #ffc400;
$dark-yellow: #FFBF00;
$light-blue: #0090ff;
$light-pink: #ff000008;
$red-icon: #fd3737;
$parrot-green: #95C11F;
$moderate-green: #5C7E00;
$greenish: #24820D;
$light-greenish: #CDE29633;
$amber: #FFAE00;
$blue: #0806EE;
$lighter-gray: #F2F2F2;
$special-gray:#545454;
$special-purple:#9435E5;

$theme-colors: (
  "primary": $primary,
  "dark-primary" : $dark-primary,
  'shed-grey' : $shed-grey,
  "backdropPrimary": $backdrop-primary,
  "secondary": $secondary,
  "error": $error,
  "gray": $gray,
  "light-greenish": $light-greenish,
  "parrot-green": $parrot-green,
  "lightGray": $light-gray,
  "darkGray": $dark-gray,
  "white": $white,
  "black": $black,
  "success": $success,
  "warning": $warning,
  "field-background": $field-background,
  "linear-background": $linear-background,
  "yellow": $yellow,
  "light-blue": $light-blue,
  "light-pink": $light-pink,
  "purple": $purple,
  "purple-v2": $purple-v2,
  "light-purple": $light-purple,
  "lighter-purple": $lighter-purple,
);

// // Typography
// //
// // Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Raleway", sans-serif !default;

// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: (
  $font-size-base * 1.125) !default;
$font-size-sm: (
  $font-size-base * 0.875) !default;
$font-size-md: (
    $font-size-base * 1.05) !default;
$font-size-xsm: (
      $font-size-base * 0.575) !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-800: 800 !default;

$font-weight-base: $font-weight-normal !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base * 0.5 !default;
$header-heading-font-size: 1.875rem !default;

$body1-font-size: $font-size-base !default;
$body2-font-size: $font-size-base * 0.875 !default;
$body3-font-size: $font-size-base * 0.75 !default;

$btn-font-size: $font-size-base !default;

$h1-line-height: $font-size-base * 3.125 !default;
$h2-line-height: $font-size-base * 2.5 !default;
$h3-line-height: $font-size-base * 1.875 !default;
$h4-line-height: $font-size-base * 1.25 !default;
$h5-line-height: $font-size-base * 0.625 !default;

$body1-line-height: $font-size-base * 1.25 !default;
$body2-line-height: $font-size-base * 1.125 !default;
$body3-line-height: $font-size-base !default;

$headings-margin-bottom: 0 !default;
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.5 !default;


$input-border-radius: 0.25rem;
$box-border-radius: 1rem;

$store-header-height: 8.5rem;
$global-header-height: 5.8125rem;
$footer-height: 6.875rem;
$header-height: 6.875rem;
$home-header-height: 22rem;
$home-header-height-xs: 18rem;
$description-header-height: 3.23rem;
$header-height-logo: 6rem;
$header-height-xs: 5.875rem;
$shared-header-height: calc(6.9375rem + 43px
);
$shared-header-height-xs: calc($header-height-xs + 20px);
$event-header-height: 9.3125rem;
$event-header-height-md: 11.875rem;
$event-header-height-sm: auto;
$portal-banner-height: 14.50rem;
$portal-banner-height-xs: 10.50rem;

//sidebox

$sidebox-width: 25.875rem;
$sidebox-height: 38.25rem;
$sidebox-height-xs: 15rem;

//button

$button-height: 3.125rem;
$button-height-xs: 2.75rem;

//space from top
$space-from-top: 6.874rem;
$space-from-top-xs: 5.874rem;

// box-shadow
$box-shadow: 0rem 0.1875rem 0.375rem #00000029;