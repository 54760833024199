.footer-buttons {
  margin-top: 3rem;
  .back-btn {
    color: $text-dark-black;
    text-transform: none;
    font-weight: $font-weight-bold;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.7rem;
    }
  }
  .footer-btn {
    margin-right: 1.125rem;
    text-transform: none;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.7rem;
    }
  }
}
