.cancel-reason-dialog {
  .MuiDialog-paper {
    width: 50rem;
    height: 21rem;
    max-width: 50rem;
    border-radius: 1rem;
  }

  &__buttons {
    & > button {
      display: inline-block;
      width: 100% !important;
      height: auto !important;  
    }

    & > .max-auto {
      max-width: auto;
    }

    & > .max-w-14 {
      max-width: 14rem;
    }
  }
}