.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    border: 1px solid #9d9d9d;
    background-color: $white;
    color: $text-black;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 22.875rem;
    width: 100%;
    max-height:4.375rem;
    min-height: 2.5rem;
    padding: 1rem;
    height: inherit;
    text-align: center;
  }
}
