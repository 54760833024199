.switch-button {
  .Mui-checked {
    .MuiSwitch-thumb {
      color: $primary;
    }
    .MuiSwitch-track {
      background-color: $primary !important;
    }
  }
}
