.MyIncestmentAdvice-container {
  min-height: calc(100vh - ($header-height + $shared-header-height));
  .MyInvestmentAdvice-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MyInvestmentAdvice {
    max-width: 59.0394rem;
    width: 100%;
    .MyInvestmentAdvice-container {
      width: 100%I;
      .MyInvestmentAdvice-head {
        background-color: $primary;
        .MyInvestmentAdviceheader-cell-lg {
          border: 1px solid $light-gray;
          font-size: $font-size-base;
          height: 3.125rem !important;
          padding: 0 !important;
          width: 32.375rem;
          color: $white;
          @media screen and (max-width: $sm-breakpoint) {
            font-size: $font-size-sm;
          }
        }
        .MyInvestmentAdviceheader-cell-sm {
          border: 1px solid $light-gray;
          color: $white;
          font-size: $font-size-base;
          height: 3.125rem !important;
          padding: 0 !important;
          width: 13.25rem !important;
          @media screen and (max-width: $sm-breakpoint) {
            font-size: $font-size-sm;
          }
        }
      }
      .MyInvestmentAdviceRow-cell-lg {
        border: 1px solid $light-gray;
        font-size: $font-size-base;
        height: 3.125rem !important;
        padding: 0 !important;
        width: 32.375rem;
        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }
      .MyInvestmentAdviceRow-cell-sm {
        border: 1px solid $light-gray;
        font-size: $font-size-base;
        height: 3.125rem !important;
        padding: 0 !important;
        width: 13.25rem !important;
        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }
    }
    .changeInvestmentAdvice-container {
      width: 100% !important;
      background: $linear-background;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      color: $white;
      text-align: center;
      @media screen and (max-width: $sm-breakpoint) {
        text-align: center;
        padding: 1rem;
      }
      .content-container{
        max-width: 50rem;
        width: 100%;
      }
    }
    .disclaimer-checkbox {
      max-width: 59.0206rem;
      width: 100%;
    }
    .save-btn-container {
      max-width: 59.0206rem;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .align-center{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
