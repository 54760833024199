.accordion {
  .accordion-summary {
    border: 1px solid $light-gray;
    flex-direction: row-reverse;
    max-height: 3.4375rem;
    height: 100%;
    padding: 1rem !important;
    .MuiAccordionSummary-expandIconWrapper {
      font-size: $font-size-lg !important;
      color: $text-black;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm !important;
      }
      &.Mui-expanded {
        transform: rotate(90deg);
      }
    }
    .accordion-summary-text {
      font-weight: $font-weight-bold;
      margin-left: 1.125rem;
      font-size: $font-size-base;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm !important;
      }
    }
  }
  .accordion-details {
    border: 1px solid $very-light-gray;
    .fund-link {
      color: $primary;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
    .MuiTypography-body1.checkbox-label {
      margin-bottom: 0;
    }
  }
}
