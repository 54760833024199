.RegistrationTerms {
  .termsAndCondition {
    color: $primary;
  }
  .KeepInTouch {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
  }
  .margin-content{
    margin-top: 1.125rem;
    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 0.875rem;
    }
  }
  .lower-content {
    font-size: 1rem;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.875rem;
    }
  }
}
