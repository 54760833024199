.ContributionCalculator{
  width: 34.9375rem;
  height: 6.25rem;
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: $sm-breakpoint) {
    width: 100%;
  }
  

  .shared-cost-form-footer {
    background-color: rgba(35, 106, 163, .10);
    width: 31.25rem;
    height: 4.125rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    padding: 0 1.5rem;
    @media screen and (max-width: $sm-breakpoint) {
      width: 85%;
      height: 6rem;
      padding: 0 1rem;
    }
    .content{
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: .75rem;
      }
    }
  }
  .calculator-icon-container {
    position: absolute;
    cursor: pointer;
    background-color: $primary;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 0;
    right: 0;
   
  }
  .calculator-icon {
    color: $white;
  }
}
