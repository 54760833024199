.profile-component {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .profile {
        .back-box {
            height: 11.125rem;
            background: $linear-background;

            @media (max-width: 600px) {
                height: 9rem;
            }
        }

        .profile-wrapper {
            z-index: 9;
            width: 100%;
            padding: 2rem;
            margin-top: -140px;

            @media (max-width: 600px) {
                padding: 1rem;
            }

            .profileSideBox-container,
            .profileBox-container {
                background-color: $white;
                width: 100%;
                min-height: 37.5rem;
                border: 1px solid $very-light-gray;
                padding: 1rem;

                @media (max-width: 900px) {
                    min-height: 20rem;
                }

            }

            .profileBox-container {
                .screenContent-container {
                    .heading {
                        text-transform: none !important;
                        font-size: $font-size-lg;
                        font-weight: $font-weight-bold;
                        color: $dark-primary;

                    }

                    .subHeading {
                        font-size: $font-size-base;
                        color: $light-gray;
                    }

                    .twoFA-confirmation-text-box {
                        background-color: $light-greenish;
                        border: 1px solid $parrot-green;
                        border-radius: 0.5rem;
                        padding: 0.5rem 1rem;
                    }
                }
            }

            .profileSideBox-container {
                padding: 1rem;

                .profile-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    padding: 1.5rem;

                    @media (max-width: 900px) {
                        padding: 0.5rem;
                        flex-direction: row;
                    }

                    @media (min-width: 600px) and (max-width: 920px) {
                        justify-content: flex-start;
                    }


                    .userProfile-img {
                        width: 11.5rem;
                        height: 11.5rem;
                        border: 1px solid $light-gray;
                        object-fit: contain;

                        @media (max-width: 600px) {
                            width: 6rem;
                            height: 6rem;
                        }

                        @media (min-width: 600px) and (max-width: 920px) {
                            width: 9rem;
                            height: 9rem;
                        }
                    }

                    .profile-card-content {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        margin-top: 1.125rem;

                        @media (max-width: 900px) {
                            align-items: flex-start;
                            margin-top: 0;
                        }

                        @media (min-width: 600px) and (max-width: 920px) {
                            margin-left: 1rem;
                        }

                        .profile-heading {
                            font-size: 1.375rem;
                            color: $dark-primary;
                            font-weight: $font-weight-bold;

                        }

                        .profile-details {
                            font-size: $font-size-base;
                            color: $black;
                            font-weight: $font-weight-bold;

                            @media (max-width: 600px) {
                                font-size: $font-size-sm;
                            }

                        }
                    }


                }
            }
        }
    }

}

.profile-buttons-container {
    @media (min-width: 600px) and (max-width: 920px) {
        display: flex;
        justify-content: stretch;
    }
}

.profileHeader {
    background: $linear-background;
    padding: 1.5rem;
    color: $white;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
}

.changePasswordContainer {
    .changepasswordheading {
        text-transform: none !important;
        color: $black !important;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
    }
}

.ProfileTabs {
    display: flex;
    justify-content: space-between;
    background-color: #F9F9F9;

    .Tabs {
        color: $light-gray;
        text-transform: none !important;
        font-weight: $font-weight-medium;
        font-size: $font-size-base;
        height: 3.75rem;

        @media (max-width: 600px) {
            font-size: $font-size-sm;
        }
    }

    .Mui-selected {
        font-weight: $font-weight-bold;
        color: $primary !important;
    }

    .MuiTabs-indicator {
        background-color: $primary !important;
        height: 0.125rem;
    }
}