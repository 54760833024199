// variables

@import "./styles/variable";

.space-from-top {
  margin-top: calc($header-height + $shared-header-height);

  @media screen and (max-width: $sm-breakpoint) {
    margin-top: calc($header-height-xs + $shared-header-height-xs);
  }
}

// Applying default color to text
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-black;
}

// Removing the spin button from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.visible-sign {
  visibility: visible;
}

.hide-sign {
  visibility: hidden;
}

.d-flex {
  display: flex;
  justify-content: center;
}

// generic components

@import "./styles/button";
@import "./styles/shared/shared_header";
@import "./styles/classes";
@import "./styles/helpdeskBtn.scss";
@import "./styles/hover_animation.scss";


// layout
@import "./styles/layout/footer";
@import "./styles/layout/header";
@import "./styles/layout/savechangesalert";

//shared

@import "./styles/shared/organisation_page";
@import "./styles/shared/home_page";
@import "./styles/shared/switch_button";
@import "./styles/shared/accordion";
@import "./styles/shared/manage_my_shared_cost";
@import "./styles/shared/formik_checkbox";
@import "./styles/shared/tooltip";
@import "./styles/shared/thankyou_page";
@import "./styles/shared/event_page";
@import "./styles/shared/loader";
@import "./styles/shared/shared-cost-application";
@import "./styles/shared/fallback";
@import "./styles/shared/signinflow-screens";
@import "./styles/shared/dialog";
@import "./styles/shared/registeration-screen";
@import "./styles/shared/myInvestmentAdvice";
@import "./styles/shared/profile_screen";
@import "./styles/shared/page_not_found";
@import "./styles/shared/home_page_button_modal";
@import "./styles/shared/payroll"
