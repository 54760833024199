  .registeration-screen-container {
    background-color: $white;
    max-width: 42.25rem;
    width: 100%;
    max-height: 50rem;
    height: 100%;
    position: relative;
    padding: 1rem 0;
    @media screen and (max-width: $md-breakpoint) {
      height: auto;
    }
  }

@import "./Unregister-User/shared-header-register";
@import "./Unregister-User/registeration-stepper-form";
@import "./Unregister-User/registration-stepper";
@import "./Unregister-User/perosnal-details";
@import "./Unregister-User/ask-for-signin";
@import "./Unregister-User/acknowlegement";