.thankyou-page-container {
  width: 100%;
  min-height: calc(100vh - $header-height);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: $header-height;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: calc(100vh - $header-height-xs);
  margin-top: $header-height-xs;

  }

  .content-wrapper {
    width: 100%;
    min-height: calc(100vh - $header-height);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-container {
      max-width: 50rem;
      min-height: 31.25rem;
      max-height: 37.5rem;
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;
      background-color: $white;
      padding: 2rem 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: $sm-breakpoint) {
        min-height: 20rem;
        padding: 1rem 1.5rem;
      }

      .img-container {
        width: 6.3125rem;
        height: 6.3125rem;

        @media screen and (max-width: $sm-breakpoint) {
          width: 4.3125rem;
        height: 4.3125rem;
          margin-bottom: 0.75rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .content {
        text-align: center;
        color: $black;
        font-size: $font-size-base;

        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }

      .head-content {
        color: $primary;
        font-weight: $font-weight-bold;
        font-size: 1.375rem;

        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-lg;
        }
      }
    }
  }
  .text-none {
    text-decoration: none;
    > * {
      text-decoration: none;
    }
  }
  a > .thankyou-btn {
    color: inherit;
    text-decoration: none;
  }
}
