.registration-stepper {
  width: 100%;
  min-height: 35rem;
  height: 100%;
  .registration-stepper-container {
    background-color: $primary;
    height: 5.5rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .Stepper{
      width: 100%;
      .StepperLable{
        font-weight: $font-weight-bold;
        font-size: 0.875rem;
        color: $white;
      }
    }
  }
}
