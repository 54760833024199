.payroll-report-header {
  height: 3.125rem;
  background-color: $lightest-purple-v2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem !important;
  font-weight: 650;
  color: $black;

  @media screen and (max-width: $md-breakpoint) {
    height: 4.125rem !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    font-size: 0.75rem !important;
    height: 5.125rem !important;
    align-items: flex-start;
    flex-direction: column !important;
    padding-left: 1rem;
  }
}