// Text Classes
.primary-text {
  color: $primary !important;
}

.dark-purple-text{
  color: $dark-purple !important;
}

.purple-text {
  color: $purple !important;
}

.purple-text-v2 {
  color: $purple-v2 !important;
}

.lighter-purple-text-v2 {
  color: $lighter-purple-v2 !important;
}

.black-text {
  color: $black !important;
}

.link-text {
  color: $blue !important;
}

.underline-text {
  text-decoration: underline !important;
}

.lowercase-text {
  text-transform: lowercase !important;
}

.capitalize-text {
  text-transform: capitalize !important;
}

.moderate-primary-text {
  color: $moderate-primary !important;
}

.success-text {
  color: $success !important;
}

.warning-text {
  color: $yellow !important;
}

.moderate-green-text {
  color: $moderate-green !important;
}

.imp-text {
  color: $warning !important;
}

.grey-text {
  color: $light-gray;
}

.bg-light-gray {
  background-color: $light-gray !important;
}

.white-text {
  color: $white !important;
}

.warn-red-text{
  color: $warn-red !important;
}

.med-purple-text{
  color: $special-purple !important;
}

.bg-moderate-red {
  background-color: $moderate-red !important;
}

.bg-primary {
  background-color: $primary !important;
}

.web-link {
  color: $purple-v2;
  text-decoration: none;
  word-break: normal;
}

.none-text-transform {
  text-transform: none !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.large-heading {
  font-size: 1.5rem !important;
}

.stepper-content {
  font-size: $font-size-base !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

//font-size
.fs-28 {
  font-size: 1.75rem !important; 
}

.fs-14 {
  font-size: 0.875rem !important; 
}

.fs-20 {
  font-size: 1.25rem !important; 
}

.p-30 {
  padding: 1.875rem !important;
}

.pr-30 {
  padding-right: 3.25rem !important;
}

.p-lr-5 {
  padding: 0 0.50rem !important;
}

.p-lr-30 {
  padding: 0 1.875rem !important;
}

.p-lr-50 {
  padding: 0 4.50rem !important;
}

// Margin Classes
.mt-5 {
  margin-top: 0.3125rem !important;
}

.mb-5 {
  margin-bottom: 0.3125rem !important;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.mt-10 {
  margin-top: 0.60rem !important;
}

.mb-10 {
  margin-bottom: 0.60rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-14 {
  margin-bottom: 0.875rem !important;
}

.ml-18 {
  margin-left: 1.125rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mt-18 {
  margin-top: 1.125rem !important;
}

.mr-18 {
  margin-right: 1.125rem !important;
}

.mb-18 {
  margin-bottom: 1.125rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.ml-36 {
  margin-left: 2.250rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mb-85 {
  margin-bottom: 5.70rem !important;
}

.mt-100 {
  margin-top: 6.850rem !important;
}

.mb-100 {
  margin-bottom: 6.850rem !important;
}

.mt-100 {
  margin-top: 6.850rem !important;
}

.mt-150 {
  margin-top: 16rem !important;
}

.mb-110 {
  margin-bottom: 9rem !important;
}

.mt-200 {
  margin-top: 21rem !important;
}

.mb-200 {
  margin-bottom: 21rem !important;
}

.mt-250 {
  margin-top: 25rem !important;
}

.mb-250 {
  margin-bottom: 25rem !important;
}

.application-page-container {
  min-height: 31.5rem;
  height: 100%;
  @media screen and (max-width: $sm-breakpoint) {
    min-height: 25.5rem;
    overflow-x: hidden;
  }
}

.modal-background {
  background-color: rgba($color: #000000, $alpha: 0.57);
}

.cursor-pointer {
  cursor: pointer;
}

// Icons
.delete-icon {
  color: $red-icon;
  cursor: pointer;
}

.arrow-right {
  color: $primary;
}

.input-field-icon {
  color: $field-icon-black;
  font-size: $font-size-lg !important;
}

.error-outline {
  color: $red-icon;
  font-size: $font-size-lg !important;
}

//stepper-field

.stepper-field {
  max-width: 31.25rem !important;
  width: 100% !important;
}

.stepper-content {
  font-size: $font-size-base !important;
  color: $black;
  line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

//sub heading
.sub-heading {
  color: $purple-v2 !important;
  font-size: $font-size-lg !important;
  font-weight: $font-weight-bold !important;
  line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-base !important;
  }
}

.strongContent {
  color: $black;
  font-weight: $font-weight-bold;
}

.investedFund-profit {
  color: $success;
  font-weight: $font-weight-bold;
}

.investedFund {
  color: $primary;
  font-weight: $font-weight-bold;
}

.lnh-2 {
  line-height: 2rem !important;
}

.grey-color {
  background-color: $light-gray !important;
}

.flex-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.width-19rem{
  width: 19rem !important;
}

.side-navigation-text {
  padding: 0.60rem 0;
}

.side-navigation-text:hover {
  cursor: pointer;
  background-color: $very-light-gray;
}

.side-navigation-highlighted {
  padding: 0.60rem 0;
  font-weight: bold !important;
  color: $primary
}

.side-navigation-highlighted:hover {
  cursor: pointer;
}

.lighter-purple-v2 {
  color: $lighter-purple-v2;
}

.PrivatePickersYear-yearButton.Mui-selected,
.MuiPickersDay-root.Mui-selected {
  background-color: $purple-v2 !important;
}

.date-picker-dialog {
  &>.MuiDialog-container {
    &>.MuiPaper-root.MuiPaper-elevation {
      .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        color: $purple-v2 !important;
      }
    }
  }
}

.italic-font {
  font-style: italic !important;
}

.bg-purple-text-white {
  background-color: $purple !important;
  color: white !important;
}

.bg-light-purple {
  background-color: $lighter-purple;
}

.generic-confirmation-dialog {
  .MuiDialog-paper {
    width: 30rem !important;
  }

  .detail-heading-box {
    padding: 1rem 2.5rem !important;
  }
}

.hover-effect-white {
  background-color: $white !important;
  &:hover {
    background-color: lighten($white, 10%) !important;
  }
}

.font-bold {
  font-weight: $font-weight-bold !important;
}

.align-self-center {
  align-self: center;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pi-0 {
  padding-inline: 0;
}

.text-start {
  text-align: start;
}

.d-block {
  display: block !important;
}

.fw-400 {
  font-weight: 400 !important;
}