.SaveChangesAlert {

    .MuiDialog-paper {
        width: 37.5rem;
        border-radius: 1rem;
        padding:3.25rem 1rem;
    }

    .alert_heading {
        text-align: center;
        color: $primary;
        font-weight: $font-weight-bold;
        padding: 0px;
    }

    .alert-message-alignment {
        display: flex;
        align-items: center;
        justify-content: center;

        .alert_message_container {
            max-width: 26.875rem;
            width: 100%;

            .alert_message {
                text-align: center;
                color: $black;
            }
        }
    }
    .alert-btn-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}