.container-wrapper {
        padding: 2rem 0;

    .signIn-container {
        height: 50.9375rem !important;
        overflow: auto;
        @media screen and (max-width: $sm-breakpoint) {
            height: auto !important;
            padding: 1rem;
        }
    }

    .signIn-heading {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        color: $primary;
    }

    .instruction-links {
        width: 100%;
        display: flex;
        flex-direction: column;

        .link {
            margin-top: 0.3125rem;
            color: $primary;
            text-decoration: none;
            font-size: $font-size-base !important;

            @media screen and (max-width: $sm-breakpoint) {
                font-size: $font-size-sm !important;
            }
        }
    }

    .registerdetails-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .registered-heading {
            font-weight: $font-weight-bold;
            color: $primary;
            font-size: $font-size-lg;
    
            @media screen and (max-width: $sm-breakpoint) {
                font-size: $font-size-base;
            }
        }
    
        .registered-details {
            text-align: center;
            color: $primary;
            font-size: $font-size-base;
    
            @media screen and (max-width: $sm-breakpoint) {
                font-size: $font-size-sm;
            }
        }
    }

}