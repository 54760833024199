.status-box {
  text-align: center;
  .error-status {
    font-weight: bold !important;
    font-size: 2.50rem !important;
    color: $dark-primary !important;
  }
}

.error-description {
  font-size: 1.5rem !important;
  color: $dark-primary !important;
}

.error-img {
  width: 100% !important;
}

.status-img {
  height: 80% !important;
  
  @media screen and (max-width: $md-breakpoint) {
    height: 75% !important;
    width: 75% !important;
  }
}