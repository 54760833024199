.organisation-large-logo {
  height: 10.5rem;
  width: 100%;
  margin-top: 12rem;

  .organisation-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.no-plan-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .strong-heading {
    color: $purple-v2;
  }

  .bottom-header {
    background-color: $light-purple;
    width: 38rem;
    padding: 0rem 3.5rem 1.5rem 3.5rem;
    border-radius: 0.5rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 17rem;
      padding: 0rem 1rem 1.75rem 1rem;
    }

    @media screen and (max-width: $xs-breakpoint) {
      width: 14rem;
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
  }
}


.back-img {
  height: 80vh;
  z-index: -9;
  margin-top: -35%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 55vh;
    margin-top: -100%;

  }

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    height: 60vh;
    margin-top: -80%;
  }

}

.back-img-sm {
  height: 31vh;
  z-index: -9 !important;
  margin-top: -15%;

  @media screen and (max-width: $lg-breakpoint) {
    height: 34vh;
    margin-top: -25%;
  }

  @media screen and (max-width: $md-breakpoint) {
    height: 33vh;
    margin-top: -31%;
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 40vh;
    margin-top: -61%;
  }

  @media screen and (max-width: $xs-breakpoint) {
    height: 35vh;
    margin-top: -100%;
  }
}

.plan-detail-container {
  width: 100%;
  border: 1px solid #D8D4D3;
  background-color: $white;

  .banner-container {
    width: 100%;
    height: 11.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0.875rem;
    }

    .plan-with-provider {
      width: 15.937rem;
      height: 4.562rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: $md-breakpoint) {
        max-width: 80%;
        max-height: 80%;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 70%;
        max-height: 70%;
      }
    }

    .plan-with-provider-text {
      font-size: 18px;
      color: #000;
      font-weight: 400;
      text-align: center;
      line-height: 1.36rem;

      @media screen and (max-width: $md-breakpoint) {
        font-size: 1rem;
        line-height: 1.19rem;
      }

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .status-heading {
      font-size: 1.125rem;
      color: $white;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }

    .status-name {
      font-size: 1.125rem;
      color: $white;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }
  }

  .info-container {
    background-color: $white;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
      padding: 0.5rem;
    }

    .align-left {
      @media screen and (max-width: $md-breakpoint) {
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }
    }

    .plan {
      color: $special-gray !important;
      font-family: "Inter", sans-serif !important;
      font-size: 1rem !important;
      font-weight: $font-weight-medium !important;
      line-height: 1.3125rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-size: $font-size-sm !important;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-size: $font-size-xsm !important;
      }
    }

    .plan .value {
      font-size: 22px !important;
      line-height: 1.664rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: 1.175rem !important;
        line-height: 1.464rem !important;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: $font-family-base!important;
        line-height: 1rem !important;
      }
    }

    .icon-btn {
      font-weight: 300 !important;
      color: $purple-v2 !important;
      line-height: 1.212rem;
      font-family: "Inter", sans-serif !important;
      width: 19rem !important;
      border: 2px solid $purple-v2 !important;
      text-transform: none !important;
      border-radius: 0.5rem !important;

      @media screen and (max-width: $md-breakpoint) {
        max-width: 16rem;
        font-size: 0.7rem !important;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 10rem;
        font-size: 0.5rem !important;
      }
    }
  }
}

.plan-guidance-container {
  background-color: $white;
  border: 1px solid #D8D4D3;
  padding: 1.875rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.875rem;
  }

  .plan-guidance-heading {
    font-size: 1.375rem;
    color: $purple-v2;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }

  .plan-guidance-content {
    color: $black;
    font-size: $font-size-base;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.video-container {
  height: 29.5625rem;
  width: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 11.5625rem;
  }
}

.service-container {
  padding: 0 1rem;

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    display: flex;
    justify-content: center;
  }

  .service-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .service-icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .service-content {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: $purple-v2;
      text-align: center;


      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }

}

.customer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-service-content {
    font-size: $font-size-base;
    color: $purple-v2;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .customer-service-button {
    width: 10rem;
    height: 3.125rem;
    background-color: $purple;
    color: $white;
    border-radius: 0.5rem;
    text-transform: capitalize !important;

    &:hover {
      background-color: $purple;
    }
  }
}

.my-plan-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  width: 85.875;
  background: $purple;
  padding: 1rem;

  .my-plan-heading {
    color: $white;
    font-weight: bold;
    font-size: 1.250rem;
    margin-bottom: 3rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 9.125rem;
  }
};

.cancel-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;
  text-align: center;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.35rem;
    margin-top: -4rem !important;
    padding: 0.50rem !important;
  }
}

.cancel-plan-content-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.35rem;
    margin-top: -4rem !important;
    padding: 0.50rem !important;
  }

  .padding-content-heading {
    font-size: $font-size-lg;
    color: $purple-v2;
    padding: 0 1.875rem !important;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: $font-size-base;
    }
  }

  .padding-content {
    padding: 0 1.875rem !important;
    color: $black;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
    }
  }
}

.width-restriction {
  min-width: fit-content !important;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.250rem !important;
    margin-top: -4rem !important;
  }
}

.avc-amount-box {
  width: 100%;
  border: 1px solid #D8D4D3;
  min-height: 10rem;
  padding: 1rem;
}

.generic-heading-cotainer {
  width: 100%;
  height: 3.75rem;
  background: $purple;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 0.3rem;
  }

  .generic-heading {
    color: $white;
    font-size: 1rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.flex-start {
  justify-content: flex-start !important;
  align-items: center !important;
}

.amend-form-tab {
  background-color: $light-purple !important;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  height: 3.650rem;

  @media screen and (max-width: $md-breakpoint) {
    height: auto;
  }

  .form-text {
    font-size: 14px;
  }

  .underline-text {
    text-decoration: underline;
  }
}

.amend-page-accordian {
  .summary {
    border: none !important;
    background-color: $light-purple !important;

    .MuiAccordionSummary-expandIconWrapper {
      color: $purple-v2 !important;
    }
  }

  .summary-text {
    font-weight: bold !important;
    color: $purple-v2 !important;
  }
}

.amendment-reason-box {
  padding: 1.3rem;
}

.amend-descripton-box {
  background-color: $moderate-gray;
  padding: 1rem;
}

.downlaod-application {
  flex-direction: row !important;  
  & > button {
    padding: 16px;
    width: 56px;
    min-width: none !important;
    &:hover {
      background-color: #eee !important;
    }
  }
  
  & > .home-typography {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    font-family: "Elza", "Inter", sans-serif !important;
    &:hover {
      color: $purple-v2;
    }
  
  }
} 

.manage-my-shared-cost * {
  font-family: 'Inter', sans-serif !important;

  .currency-contribution {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .payroll-table {
    margin-left: 53px;

    @media screen and (max-width: $md-breakpoint) {
      margin-left: 10px;
    }
    
    @media screen and (max-width: $sm-breakpoint) {
      margin-left: 0px !important;
    }
  }

  .currency {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px;
  }

  .amend-container {
    margin-left: 20px;

    @media screen and (max-width: $sm-breakpoint) {
      margin-left: 0px !important;
    }
    & > button {
      padding: 16px;
      width: 56px;
      &:hover {
        background-color: #eee;
      }
      min-width: none;
    }
  }
}