.stepper-layout {
  margin-top: $shared-header-height;
  .application-form {
    .form-heading-container {
      height: 3.125rem;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      margin-bottom: 1.4375rem;

      .heading {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        margin-left: 0.75rem;
      }
    }


    .form-container {
      max-width: 31.25rem;
      width: 100%;
      min-height: 32.5rem;

      .textbox {
        margin-top: 1.125rem;
      }
    }
  }

  .stepper-grid {
    @media screen and (max-width: $md-breakpoint) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .stepper-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
  }
}
