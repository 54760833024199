.chatbtn {
    background: $linear-background;
    position: fixed !important;
    bottom: 7rem;
    right: 1.5rem;
    z-index: 9999;
    width: 7.375rem;
    height: 3.125rem;
    border-radius: 2.5rem !important;
    color: white;
    display: flex;
    justify-content: center;
    text-transform: capitalize !important;
    align-items: center;
    box-shadow: $box-shadow;
    text-decoration: none;
    @media screen and (min-width:$sm-breakpoint) and (max-width:$md-breakpoint) {
      border-radius: 50%;
      bottom: 14rem;
      right: 1rem;
    }
  
    @media screen and (max-width: $sm-breakpoint) {
      width: 4.125rem;
      height: 4.125rem;
      border-radius: 50%;
      bottom: 1rem;
      right: 1rem;
    }
  
    .chat-icon {
      color: $white;
      width: 1.2rem;
      height: auto;
      @media screen and (max-width: $sm-breakpoint) {
        width: 1.8rem;
      }
    }
    .chat-content {
      color: $white;
      font-weight: bold;
      @media screen and (max-width: $sm-breakpoint) {
        display: none;
      }
    }
  }

.imbtn {
  background: $linear-green-background-light !important;
  bottom: 11rem !important;
  text-transform: none !important;
  padding: 1.3rem 0.7rem !important;

  @media screen and (min-width:$sm-breakpoint) and (max-width:$md-breakpoint) {
    bottom: 18rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    bottom: 6rem !important;
  }

  .chat-icon {
    color: $white;
    width: 1.8rem;
    height: auto;
    @media screen and (max-width: $sm-breakpoint) {
      width: 2.40rem;
    }
  }
}

.staffBenefitsButton {
  background: #9C1F7D !important;
  bottom: 34rem  !important;
  right: 0 !important;
  height: 3.125rem;
  text-transform: none !important;
  width: auto !important;
  max-width: 13rem !important;
  border-radius: 2.5rem 0 0 2.5rem !important;
  position: fixed !important;
  z-index: 9999;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  box-shadow: $box-shadow;
  text-decoration: none;

  .vivup-icon {
    color: $white;
    width: 2.5rem;
    height: auto;
  }

  .content {
    color: $white;
    font-weight: bold;
    text-align: left;
  }

  .font-elza {
    font-family: 'Raleway', sans-serif !important;
  }
}
  