.investment-choices {
  .freeadvice-container {
    background: $linear-background;
    width: 100%;
    min-height: 10.75rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    .content{
      color: $white;
      text-align: center;
    }
  }
  .checkbox-info {
    font-weight: $font-weight-bold;
  }

  .investment-advice-btn {
    background: $success;
    font-size: 14px;
    &:hover {
      background: $success;
    }
  }
}
