.stepper-component{
    width: 100%;
    height: 100%;
    margin-bottom: 20px
}

@import "./shared-cost-application/final_summary";
@import "./shared-cost-application/final_summary_box_header";
@import "./shared-cost-application/chosen_investment_table";
@import "./shared-cost-application/form_header";
@import "./shared-cost-application/shared_cost_application_page";
@import "./shared-cost-application/acknowledgement-and-signature";
@import "./shared-cost-application/footer_buttons";
@import "./shared-cost-application/shared_cost_form";
@import "./shared-cost-application/contribution_calculator_modal";
@import "./shared-cost-application/shared_cost_avc_amount_step_2";
@import "./shared-cost-application/shared_cost_avc_amount_step_3";
@import "./shared-cost-application/investment_choices";
@import "./shared-cost-application/investment_choices_step_2_provider";
@import "./shared-cost-application/stepper-box";
