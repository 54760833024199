.btn {
  height: $button-height;
  width: 15.0938rem;
  text-decoration: none;
  font-size: $font-size-base;
  box-shadow: none !important;
  text-transform: none !important;
  line-height: 1rem !important;

  @media screen and (max-width: $md-breakpoint) {
    max-width: 14rem;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 8rem;
    font-size: 0.7rem !important;
  }
}

.mod-btn {
  height: $button-height;
  width: 15.0938rem;
  text-decoration: none;
  font-size: $font-size-base;
  box-shadow: none !important;
  text-transform: none !important;
  line-height: 1rem !important;

  @media screen and (max-width: $md-breakpoint) {
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 14rem;
    font-size: 0.7rem !important;
  }
}

.primary-clr-btn {
  text-transform: none !important;
  width: 17rem !important;
  color: $white !important;
  background-color: $purple !important;
  border-radius: 0.5rem !important;

  @media screen and (max-width: $lg-breakpoint) {
    max-width: 16.0938rem !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $md-breakpoint) {
    max-width: 17rem !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 15rem !important;
    font-size: 0.8rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    max-width: 13rem !important;
    font-size: 0.7rem !important;
  }

}

.service-button {
  width: 17.8125rem !important;
  height: 50px !important;
  background-color: $purple !important;
  color: $white !important;
  text-transform: capitalize !important;
  border-radius: 0.5rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 15.8125rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    width: 14rem !important;
    font-size: 0.9rem !important;
  }
}

.large-service-button {
  width: 20.8125rem !important;
}

.disable-text-transform {
  text-transform: none !important;
}

.service-border {
  border: 1px solid #151A44 !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 11.8125rem !important;
    font-size: 0.9rem !important;
  }
}

.org-btn {
  border: 1px solid $light-primary !important;
  border-radius: 0.5rem !important;
  background: $dark-primary !important;

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 12rem;
    font-size: 0.8rem !important;
  }
}

.confirmation-warning {
  background-color: $warn-red !important;
  border: 1px solid $warn-border !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 11.8125rem !important;
    font-size: 0.9rem !important;
  }
}

.smallbtn {
  width: 7rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 8rem;
    font-size: 0.7rem !important;
  }
}

.mediumbtn {
  width: 10rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  text-transform: none !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 8rem !important;
    font-size: 0.7rem !important;
  }
}

.thinbtn {
  width: 12rem !important;
  border-radius: 0.5rem !important;
  height: 2.8125rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 11rem !important;
    font-size: 0.8rem !important;
  }
}

.largebtn {
  width: 18.0938rem !important;

  @media screen and (max-width: $md-breakpoint) {
    max-width: 16rem;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 15rem;
    font-size: 0.7rem !important;
  }
}

.bg-ligter-primary {
  background-color: $lighter-primary !important;
}

.eventbtn {
  height: 3rem !important;
  font-weight: bold !important;
  border-radius: 0.5rem !important;
  padding: 0.3rem !important;
  width: 16.0938rem;
  color: $white !important;
  background-color: $moderate-primary !important;

  @media screen and (max-width: $md-breakpoint) {
    max-width: 16.0938rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 16.0938rem !important;
    font-size: 0.9rem !important;
  }
}

.btn-border-radius {
  border-radius: 0.5rem !important;
}

.backbtn {
  color: #313131 !important;
  text-transform: capitalize !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm ;
  }
}

.light-backbtn {
  color: #141414 !important;
  text-transform: none !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm ;
  }
}

.disable-backbtn {
  text-transform: capitalize !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm ;
  }
}

.event-availablilty-btn {
  border: 2px solid $parrot-green !important;
  width: 11.3rem;
  height: 2.6rem;
  border-radius: 0.5rem !important;
  padding: 0.1rem !important;
  font-weight: bold !important;
  color: $dark-primary !important;

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 11.3rem;
    font-size: 0.8rem !important;
  }
}

.im-btn {
  color: $white !important;
  border-radius: 0.5rem !important;
  background: $parrot-green !important;
  font-weight: bold !important;
  font-weight: 400;
  height: 2.6rem;
  border-radius: 0.5rem !important;
  padding: 0 !important;

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 16rem;
    margin-top: 1rem !important;
    font-size: 0.95rem !important;
  }
}

.Header-outlined-btn-sm {
  max-width: 7rem !important;
  padding: 0.5rem 1.5rem !important;
  text-align: center !important;
  display: grid !important;
}

.Header-outlined-btn {
  color: $white !important;
  max-width: 10rem;
  text-transform: none !important;
  text-align: left;
  max-height: 3.5rem;
  height: 100%;
  width: 100%;
  font-size: 0.875rem !important;
  border: 1px solid $white !important;
  box-shadow: none;
  display: flex;
  justify-content: space-between !important;
  align-items: space-between;

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 90%;
    color: $purple;
    border: 1px solid $purple !important;
  }

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    max-width: 50%;
    color: $purple;
    border: 1px solid $purple !important;
  }

  .keydownIcon {
    color: white;

    @media screen and (max-width: $md-breakpoint) {
      color: $purple;
    }
  }

  .dropdown-text {
    color: $white;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap;

    /* let the text wrap preserving spaces */
    @media screen and (max-width: $md-breakpoint) {
      color: $purple;
      -webkit-line-clamp: 3;
    }
  }

  .moreoption {
    color: $white;
    font-size: $font-size-sm;

    @media screen and (max-width: $md-breakpoint) {
      color: $purple;
    }
  }
}

.contained-btn {
  color: $white !important;
  background-color: $linear-background !important;
}

.outlined-btn {
  color: $primary !important;
  background: $white;
  border: 1px solid $linear-background !important;
}

.success-btn {
  width: 22rem;
  height: 3.125rem;
  color: white !important;
  background-color: $success !important;
  text-transform: none !important;
  font-size: $font-size-base !important;

  @media screen and (max-width: $sm-breakpoint) {
    width: 16rem;
    height: 2.8rem;
    font-size: $font-size-sm !important;
  }
}

.confirmation-btn {
  width: 11.875rem;
  height: 2.5rem;
  text-transform: none !important;
  color: $white;
  border-radius: 0.5rem !important;
  font-weight: bold !important;
}

.ningi-journey-button {
  height: 3.125rem;
  max-width: 23.125rem;
  width: 100%;
  color: white !important;
  background-color: $success !important;
  text-transform: none !important;
  font-size: $font-size-base !important;

  @media screen and (max-width:$md-breakpoint) and (min-width:$sm-breakpoint) {
    margin-top: 1rem !important;
  }

  @media screen and (max-width: $sm-breakpoint) {
    width: 20rem;
    height: 2.8rem;
    font-size: $font-size-sm !important;
    margin-top: 1rem !important;
  }

}

.thankyou-btn {
  height: $button-height;
  width: 15.0938rem;
  text-decoration: none;
  font-size: $font-size-base;
  box-shadow: none !important;
  text-transform: none !important;
  text-align: center;
}

.signinflow-btn {
  height: $button-height;
  max-width: 15.0938rem;
  width: 100%;
  text-decoration: none;
  color: $white !important;
  background: $linear-background;
  font-size: $font-size-base;
  box-shadow: none !important;
  text-transform: capitalize !important;
  text-align: center;
}

.dropdown-item {
  background: #ffffff;

  button {
    color: black;
    margin: 0;
    padding: 0;
    font-size: small;
    font-weight: normal;
    text-transform: none;
  }

  &:hover {
    background: $purple !important;
    color: white;

    button {
      color: white;
    }
  }
}

.dropdown-item.selected-item {
  background: $purple;

  button {
    color: white;
    font-weight: bold;
  }
}

.profile-button {

  display: flex;
  justify-content: flex-end !important;
  width: 100%;
  color: #171C48 !important;
  background-color: $white;
  border: 1px solid #171C48 !important;
  font-size: $font-size-base;
  text-transform: none !important;
  padding: 1rem !important;
  border-radius: 0 !important;
  font-weight: $font-weight-medium !important;

  @media (max-width: 600px) {
    justify-content: center !important;
    padding: 0.8rem !important
  }

  @media (min-width: 600px) and (max-width: 920px) {
    justify-content: center !important;
    width: 33.3%;
  }
}

.contained-profile-btn {
  color: $white !important;
  background: #171C48 !important;
}

.contained-warning-btn {
  color: $white !important;
  background: $warn-red !important;
}

.activeprofileButton {
  background-color: #171C48 !important;
  color: $white !important;
}

.profile-btn {
  min-width: 16rem !important;
  height: $button-height;
  text-decoration: none;
  border-radius: 8px !important;
  font-size: $font-size-base;
  text-transform: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  line-height: 1rem !important;
  padding: 0 1.5rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 8rem;
    font-size: $font-size-sm !important;
  }
}

.book-place-btn {
  font-weight: $font-weight-bold !important;
  color: $purple-v2 !important;
  line-height: 1.512rem;
  font-family: 'Inter', sans-serif;
  width: 17rem !important;
  border: 1px solid $purple-v2 !important;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  font-size: $font-size-base !important;

  @media screen and (max-width: $md-breakpoint) {
    max-width: 16rem;
    font-size: 0.9rem !important;
  }  

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 12rem;
    font-size: 0.6rem !important;
  }
  @media screen and (max-width: $xsm-breakpoint) {
    max-width: 10rem;
    font-size: 0.6rem !important;
  }
}


.apply-now-btn {
  &:hover {
    background-color: #E5A8FB !important;
    color: #391474 !important;
  }
}
