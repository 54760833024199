.dialog {
  &-modal-background {

    .MuiDialog-paper {
      width: 37.5rem;
      border-radius: 1rem;
      gap: 10px;
      padding: 2rem 2rem;
    }

  }
  &-heading {
    color: $primary;
    text-align: center;
    opacity: 1;
    letter-spacing: 0px !important;
    font: normal normal bold 18px/25px Raleway !important;
  }

  &-message {
    text-align: center;
    font: normal normal normal 16px/25px Raleway;
    letter-spacing: 0px;
    color: #313131 !important;
    opacity: 1 !important;
  }

  &-btn-container {
    justify-content: center !important;
  }
}
.change-investment-dialog {
  .dialog {
    &-heading {
      padding: 0px 18px;
    }

    &-message_container {
      padding: 0px 24px;
    }
  }
}

.consent-dialog {
  .dialog {
    &-heading {
      padding: 0;
    }
    &-message {
      white-space: pre-line;
    }
    &-message_container {
      padding: 0px;
    }
  }
}
