.table {
  .table-head {
    background-color: $primary;
    .table-cell {
      color: $white;
      font-weight: $font-weight-bold;
      height: 3.125rem !important;
      padding: 0 !important;
      font-size: $font-size-base;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }
  .table-cell {
    border: 1px solid $light-gray;
    font-size: $font-size-base;
    height: 3.125rem !important;
    padding: 0 !important;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}
