.home-modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1300;
  position: fixed;

  .button-modal {
    position: relative;
    background-color: $white;
    margin-top: 1rem;
    max-height: 95vh;
    max-width: 41.94;
    overflow-y: auto !important;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
}

.header-sideline {
  width: 0.4375rem;
  height: 4.375rem;
  background-color: $purple-v2;
  border-radius: 1.875rem;
  display: flex; 
  justify-content: center; 
  align-items: center; 

  @media screen and (max-width: $sm-breakpoint) {
    height: 4em;
  }
}

.header-heading {
  font-family: 'Inter', sans-serif !important;
  line-height: 2.269rem !important;
  font-size: $header-heading-font-size !important;
  font-weight: $font-weight-bold !important;
  color: $purple-v2 !important;
  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-lg !important;
  }
}

.standard-avc-box {
  background-color: $lighter-gray;
}