.stepper {
  padding: 1.8125rem 0rem 1.8125rem 0rem;
  width: 100%;

  .stepper-label {
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.7rem;
    }
  }

  .stepper-detail {
    color: $white;
    font-size: $font-size-base;
    cursor: pointer;
  }

  .MuiStepLabel-vertical {
    padding: 0 !important;
    width: 100%;
    justify-items: end;
  }

  .MuiStepLabel-labelContainer {
    order: 1;
    width: 80%;
    display: flex;
    justify-content: flex-end !important;
    text-align: end;
    padding-right: 1rem;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
      justify-content: center !important;
      text-align: center;
      padding-right: 0rem;


    }
  }

  .MuiStepLabel-iconContainer {
    order: 2;
  }

  .MuiStepConnector-vertical {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.09rem;
  }
}