.SharedHeaderRegister {
  position: relative;
  z-index: 99;
  height: 4.5625rem;
  .SharedHeader-sideline {
    width: 5px;
    height: 2.5rem;
    background-color: $purple;
    border-radius: 1.25rem;
    @media screen and (max-width: $sm-breakpoint) {
      height: 2rem;
    }
  }
  .SharedHeader-heading {
    font-size: 1.4375rem;
    font-weight: $font-weight-bold;
    color: $purple-v2;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-lg;
    }
  }
  .organisation-logo-container {
    max-width: 10rem;
    height: 4.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm-breakpoint) {
      max-width: 6rem;
    }
    .organisation-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.bottomspace-sharedheader {
  .bottomspace-sharedheader {
    height: 43px;
    width: 100%;
  }
}
