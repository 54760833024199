.front-screen-container {
    width: 100%;
    min-height: calc(100vh - $header-height);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $header-height;

    @media screen and (max-width: $sm-breakpoint) {
        min-height: calc(100vh - $header-height-xs);
        margin-top: $header-height-xs;

    }

    .content-wrapper {
        width: 100%;
        min-height: calc(100vh - $header-height );
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center ;
        @media screen and (max-width: $sm-breakpoint) {
            align-items: flex-start;
        }

        .content-container {
            max-width: 41.9375rem;
            height: 29.875rem;
            width: 100%;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83));
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: $sm-breakpoint) {
                height: auto !important;
            }

            .inner-content-container {
                max-width: 31.25rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .img-container {
                    width: 11.625rem;
                    height: auto;

                    @media screen and (max-width: $sm-breakpoint) {
                        width: 9rem;
                    }

                    .logo-img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .text-field {
                    max-width: 31.25rem;
                    width: 100%;
                }

                .content {
                    font-weight: $font-weight-bold;
                    text-align: center;
                    color: $primary;
                    font-size: $font-size-base;

                    @media screen and (max-width: $sm-breakpoint) {
                        font-size: $font-size-sm;

                    }
                }

                .lower-content {
                    text-align: center;
                    color: $primary;
                    font-size: $font-size-base;

                    @media screen and (max-width: $sm-breakpoint) {
                        font-size: $font-size-sm;
                    }

                }
            }
        }
    }
}


@import './signin-flow/email-verification';
@import './signin-flow/signin';