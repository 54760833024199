.final-summary-box-header {
  padding-right: 1rem;
  .heading {
    color: $text-dark-black;
  }
  .change-btn {
    text-transform: none;
    font-size: 0.75rem;
    padding: 3px 12px;
  }
}
