.contribution-calculator-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 9rem;
  margin-bottom: 3rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 1rem;
  }

  .contact-us {
    width: 40%;

    @media screen and (max-width: $md-breakpoint) {
      width: 90%;
    }
  }

  .amount {
    color: $purple-v2;
    font-size: 1.375rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }
}
.calculator-btn{
  background: $purple !important;
}
.calculater-heading{
  color: $purple-v2 !important;
}
.contribution-modal-background {
  .contribution-calculator-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 2rem;
    margin-top: 0;
    margin-bottom: 0;  
    border-radius: 1rem;
    text-align: center;
    max-width: 43.3125rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: $sm-breakpoint) {
      width: 90%;
      max-height: 90vh; 
      overflow: hidden;
      .calculator-form { 
        flex: 1;
        overflow-y: auto;
        padding-bottom: 1rem;
      }

    
    }

    .contact-us {
      width: auto;
    }
  }
}

