.AskForSignIn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 7.6875rem !important;
  height: 100%;
  padding: 0 1rem;

  @media screen and (max-width:$sm-breakpoint) {
    max-height: 12rem !important;
  }

  .AskForSignIn-Container {
    background-color: $primary;
    height: 100%;

    .Heading {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      color: $white;

      @media screen and (max-width:$sm-breakpoint) {
        text-align: center;
        font-size: $font-size-base;
      }
    }

    .details {
      color: $white;
      @media screen and (max-width:$sm-breakpoint) {
        text-align: center;
        font-size: $font-size-sm;
      }

    }

    .btn-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .signIn-btn {
        max-width: 14.0625rem;
        width: 100%;
        text-transform: none;
        background-color: $white;
        color: $primary;
        font-size: $font-size-base !important;
      }
    }
  }
}