.fall-back {
  margin-top: calc($header-height);
  h2 {
    text-align: center;
    padding: 20px;
    color: $primary;
  }
  &-stack {
    p {
      color: $primary;
      font-size: 21px;
    }
  }
  &__middle {
    display: flex;
    flex-direction: row;
  }
  &__heading {
    margin: 0px;
    font-size: 60px;
    margin-top: 40px;
    padding: 0px;
    line-height: 51px;
    text-align: center;
    width: 100%;
    color: $primary;
  }
  &_image_1 {
    height: 70%;
  }
  &_image {
    height: auto;
  }
}