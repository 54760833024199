.Acknowledgement-and-signature {
  .signature-container {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e3e3e3;
    border-radius: 0.375rem;
    height: 24.3125rem;
    padding: 1rem;

    .form-control {
      width: 100%;

      .signature {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .signature-heading {
          font-weight: $font-weight-bold;
        }

        .clear-btn {
          text-transform: none;
          color: $light-gray;
        }
      }
    }
  }
}
